import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { PUT, POST, DELETE } from "../callApiWrapper";

export function* addCommentSaga(action) {
  yield put(actions.addCommentStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json",
  };
  const response = yield POST(
    `${action.server}api/vume/eventComment`,
    action.object,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.addCommentSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addCommentFail(response));
  }
}

// ======================================= SEARCH COMMENTS

export function* searchCommentsSaga(action) {
  yield put(actions.searchCommentsStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json",
  };
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.object.order,
          property: "id",
        },
      ],
      pageNumber: action.object.pageNumber,
      pageSize: action.object.pageSize,
    },
    searchModel: {
      eventId: action.object.eventId,
      parentId: action.object.parentId ? action.object.parentId : "",
      ids: action.object.ids ? action.object.ids : [],
      statuses: action.object.statuses ? action.object.statuses : ["ACTIVE"],
      shouldCheckParentId: action.object.hasOwnProperty("shouldCheckParentId")
        ? action.object.shouldCheckParentId
        : true,
    },
  };

  const response = yield POST(
    `${action.server}api/vume/eventComment/search`,
    data,
    headers
  );
  if (response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchCommentsSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchCommentsFail(response));
  }
}

// ======================================= SEARCH COMMENT REPLIES

export function* searchCommentRepliesSaga(action) {
  yield put(actions.searchCommentRepliesStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json",
  };
  const data = {
    pageModel: {
      orders: [
        {
          dir: action.object.order,
          property: "id",
        },
      ],
      pageNumber: action.object.pageNumber,
      pageSize: action.object.pageSize,
    },
    searchModel: {
      eventId: action.object.eventId,
      parentId: action.object.parentId ? action.object.parentId : "",
    },
  };
  const response = yield POST(
    `${action.server}api/vume/eventComment/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.searchCommentRepliesSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.searchCommentRepliesFail(response));
  }
}

// ======================================= DELETE COMMENT

export function* deleteCommentSaga(action) {
  yield put(actions.deleteCommentStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token,
  };

  const response = yield DELETE(
    `${action.server}api/vume/eventComment/${action.id}`,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.deleteCommentSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deleteCommentFail(response));
  }
}

// ======================================= EDIT COMMENT

export function* editCommentSaga(action) {
  yield put(actions.editCommentStart());
  const headers = {
    "X-Auth-Token": action.token,
    "Content-type": "application/json",
  };
  const response = yield PUT(
    `${action.server}api/vume/eventComment`,
    action.object,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response === 451) {
    yield put(actions.permissionErrorSuccess(Math.random()));
  } else if (response && response.status === 200) {
    yield put(actions.editCommentSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editCommentFail(response));
  }
}
// ======================================= BLOCK COMMENT

export function* blockCommentSaga(action) {
  yield put(actions.blockCommentStart());
  const response = yield PUT(
    `${action.server}api/vume/eventComment/block/${action.id}`,
    null,
    { "Content-Type": "application/json", "X-Auth-Token": action.token }
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.blockCommentSuccess(response.status));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.blockCommentFail(response));
  }
}
