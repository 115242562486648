export const texts = {
  feeds: 'Feeds',
  all: 'All',
  groupLive: 'Group Live',
  blog: 'Blog',
  faq: 'FAQ',
  failed: 'Failed',
  success: 'Success',
  next: 'Next',
  changePassword: 'Change Password',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  resetPassword: 'Reset Password',
  email: 'Email',
  reset: 'Reset',
  resend: 'Resend',
  send: 'Send',
  username: 'Username',
  login: 'Login',
  logout: 'Logout',
  forgotPassword: 'Forgot Password',
  createAccount: 'Create Account',
  fullname: 'Full Name / Display Name',
  selectCountry: 'Select Country',
  country: 'Country',
  join: ' Join Us',
  editCamera: 'Edit Camera',
  addCamera: 'Add Camera',
  name: 'Name',
  position: 'Position',
  brand: 'Brand',
  cameras: 'Cameras',
  detectedCamera: 'System detected camera(s)',
  defaultCamera: 'Default Camera',
  useDefaultCamera: 'Define Name, Position, usage',
  title: 'Title',
  goLiveTitle: 'Add a title for your video',
  goLiveTags: 'Hashtags',
  goLiveTagsDescription: 'Type #tag keywords here',
  addedCameras: 'Added Cameras',
  additionalCameras: 'Additional Cameras',
  addRTMP: ' Add RTMP Streams',
  fbSignIn: 'Sign in with Facebook',
  appleSignIn: 'Sign in with Apple',
  upgradeToPremium: 'Upgrade to the Premium plan',
  yesConfirm: 'Yes, Confirm',
  yes: 'Yes',
  no: 'No',
  done: 'Done',
  ok: 'OK',
  select: 'Select',
  cancel: 'Cancel',
  details: 'Details',
  cameraDetails: 'RTMP Details',
  eventDetails: 'Event Details',
  others: 'Others',
  participants: 'Participants',
  update: 'Update',
  goLive: 'Go Live',
  schedule: 'Schedule',
  caption: 'Caption',
  image: 'Image',
  tags: 'Tags',
  typeAnything: 'Type a tag word',
  startTime: ' Start time',
  submit: 'Submit',
  enterJoinCode: 'Enter Join Code',
  scheduleDetail: 'Schedule Details',
  joinCode: 'Join Code',
  groupBrodcastingJoinCode: 'Join Code',
  cameraAngles: 'Camera Angles/Positions',
  troubleshoot: 'Send a report',
  videoInput: 'Video Input',
  audioInput: 'Audio Input',
  myCams: 'My Cams',
  finish: 'Finish',
  pause: 'Pause',
  start: 'Start',
  stop: 'Stop',
  wait: 'Please wait',
  readyToLive:
    'Set up your camera using the information provided below, then go live using a third-party camera app.',
  live: 'LIVE',
  live2: 'Live',
  upcoming: 'UPCOMING',
  past: 'PAST',
  cams: 'Cameras',
  report: 'Report',
  inviteFriends: 'Invite friends',
  invite: 'Invite',
  removeInvitation: 'Remove invitation',
  biography: 'Biography',
  addmoreCamera: 'Add another camera',
  jumpLive: 'Go Live',
  viewDetail: 'View Details',
  edit: 'Edit',
  delete: 'Delete',
  watch: 'Watch',
  broadcast: 'Broadcast',
  editCameras: 'Edit Cameras',
  noVideos: 'No Videos',
  back: 'Back',
  free: 'Free',
  premium: 'Premium',
  continueFree: 'Continue free trial',
  gender: 'Gender',
  location: 'Location',
  birthday: 'Birthday',
  searchbar: 'Search for tags, titles and users',
  searchOn: 'Search in',
  noResult: ' No Result',
  startCaptureWith: 'Start capture with',
  enterWifiSSID: 'Enter the Network SSID',
  enterWifiPassword: 'Enter Wi-Fi Password',
  generateQr: 'Generate QR code',
  scanQrCode: 'Please scan QR code with YI-4K camera.',
  url: 'URL',
  resolution: 'Resolution',
  framePerSecond: 'Frame per second',
  myProfile: 'My Profile',
  teamAdmin: 'Group/Multi Camera Admin',
  teamMember: 'Group Member',
  goNow: 'Go Live',
  enter: 'Enter',
  noCaption: 'No caption',
  pin: 'Pin',
  configPermissions: 'Configure permissions',
  startThumbnails: 'Start all thumbnails',
  stopThumbnails: 'Stop all thumbnails',
  playingNow: 'Playing Now',
  play: 'Play',
  skip: 'Skip',
  agreement: 'Agreement',
  uploadImage: 'Browse',
  signinWith: 'Sign in with',
  writeComment: 'Write a comment',
  addComment: 'Submit',
  share: 'Share',
  shareJoinCode: 'Share the join code',
  recover: 'Recover',
  myChannel: 'My Channel',
  processing: 'Processing...',
  promotionCode: 'Promotion Code',
  privacyPolicy: 'Privacy Policy',
  termsAndCondition: 'Terms and Conditions',
  privacy: 'Privacy',
  terms: 'Terms',
  resume: 'Resume',
  views: 'views',
  reactions: 'reactions',
  finishBroadcast: 'Finish Broadcast',
  verificationCode: 'Verification Code',
  termsTitileInfo:
    "By clicking on Sign up, you agree to ViuLive's Terms and Conditions of Use.",
  noVideoSignal: 'This camera currently has no video signal.',
  unmute: 'Unmute',
  mute: 'Mute',
  plans: {
    freeLive: ' One live stream at a time',
    freeUpcoming: '1 upcoming live event at a time',
    freeParticipants: '2 Participants',
    freeCameraParticipants: 'Two cameras per participant',
    freeHourBroadcast: '1-hour live broadcast',
    freeArchive: '14-day storage',
    premiumLive: 'Two live streams at a time',
    premiumUpcoming: '2 upcoming video at the time',
    premiumParticipants: '4 Participants',
    premiumCameraParticipants: 'Four cameras per participant',
    premiumHourBroadcast: '2-hour live broadcast',
    premiumArchive: '28-day storage'
  },
  shareObj: {
    shareEmail: 'Share via Email',
    shareFacebook: 'Share on Facebook',
    shareLinkedin: 'Share on LinkedIn',
    shareTelegram: 'Share via Telegram',
    shareWhatsapp: 'Share via WhatsApp',
    shareTwitter: 'Share on Twitter',
    shareViber: 'Share via Viber',
    shareLine: 'Share via Line'
  },
  reportObj: {
    reportSuccess: 'Thanks for reporting',
    reportSuccessDetail:
      'We have received your report. Thank you for helping to keep this community safe and fun. Our team will review the event and remove it if it violates our Terms and Conditions of Use.',
    selectIssue: 'Why are you submitting this report?',
    inappropriate: 'Inappropriate content',
    quality: 'Poor quality',
    spam: 'Spam, Scams, or Bots',
    fake: 'Fake video',
    dislike: " I just don't like it",
    immediateDanger:
      "Immediately contact the local emergency services if someone is in immediate danger. Don't wait!",
    nudity: 'Nudity',
    violence: 'Violence',
    harassment: 'Bullying or Harassment',
    suicide: 'Suicide or Self-injury',
    hateSpeech: 'Hate Speech',
    terrorism: 'Terrorism',
    unauthorisedSales: 'Unauthorized sales',
    spamOrScam: 'Spam, Scams, or Bots'
  },
  inviteObj: {
    inviteEmail: 'Invite via Email',
    inviteFacebook: 'Invite via Facebook',
    inviteTelegram: 'Invite via Telegram',
    inviteWhatsapp: 'Invite via Whatsapp',
    inviteTwitter: 'Invite via Twitter',
    inviteLine: 'Invite via Line'
  },
  cancelEdit: 'Cancel Edits',
  saySomething: 'Say something!',
  of300: '{{count}} of 300',
  loadMoreReplies: 'Load more replies',
  comment: '{{numberOfComments}} comments',
  comments: 'comments',
  replies: '{{replyNumber}} replies',
  hide: 'Hide',
  show: 'Show',
  cancelReplying: 'Cancel',
  replyTo: 'Reply',
  cancelEditing: 'Cancel Editing',
  iAgreeToTheViuliveAgreement: "I've read and agreed to the Terms & Conditions",
  emailUsername: 'Email / Username',
  selectYourCountry: 'Select Country',
  selectYourBirthday: 'Select birthday',
  dismiss: 'Dismiss',
  none: 'None',
  thanksForWatching: 'Thanks for watching!',
  thanksForBroadcasting: 'Thanks for broadcasting!',
  finishedByTheBroadCaster: 'This event has ended.',
  finishedByTheBroadCaster2: 'Broadcast has ended',
  numberOfCams: '{{length}} Camera(s)',
  warning: 'Warning',
  participant: '{{length}} Participant(s)',
  camera: 'Camera',
  selectCameraDirection: 'Select Camera Direction',
  selectCameraBrand: 'Select Camera Brand',
  selectCameraPosition: 'Select Camera Position',
  selectStartTime: 'Select Start Time',
  selectYourGender: 'Select Gender',
  followers: 'Followers',
  followings: 'Followings',
  follow: 'Follow',
  unfollow: 'Unfollow',
  goToMyChannel: 'Go to MyChannel',
  unblock: 'Unblock',
  block: 'Block',
  blocked: 'Blocked',
  blockedAccounts: 'Blocked Accounts',
  stopThisCamera: 'Stop this camera broadcast',
  stopAllCameras: 'Stop all camera broadcasts',
  openSetting: 'Open setting',
  rememberMe: 'Remember me',
  webCam: 'Webcam',
  screenSharing: 'Screen Sharing',
  rtmp: 'RTMP',
  reStreaming: 'Re-Streaming',
  facebook: 'Facebook',
  youtube: 'YouTube',
  useYoutube: 'Use YouTube',
  setupNewConfig: 'Setup new config',
  pressToSelect: 'Tap to select',
  pressToSelectScreenSharing: 'Tap to enable screen sharing',
  newScreenSharing: 'New screen sharing',
  stopSharing: 'Stop sharing',
  selectACamera: 'Select a camera',
  topEvents: 'Browse Top Events',
  browseLiveVod: 'Browse Live & VODs',
  selectGridMode: '{{screenNumber}} Screen',
  recentlyUsed: 'Recently Used',
  emojis: 'Emojis',
  viaSocialMedia: 'via Social Media',
  serverURL: 'Server URL',
  connecting: 'Connecting...',
  linkToYoutube: 'Connect YouTube Events',
  linkToFacebook: 'Connect Facebook',
  enterEventPassword: 'Enter event password',
  chooseThePlanThatWorksForYou: 'Choose a plan that’s right for you',
  comingSoon: 'Coming Soon!',
  playHere: 'Play here',
  guest: 'Guest',
  typeAMessage: 'Type a message...',
  chatWithTheBroadcaster: 'Chat with the broadcaster',
  chatWithTheViewer: 'Chat with the viewers',
  home: 'Home',
  searchGifs: 'Search GIFs',
  searchEmojis: 'Search Emojis',
  viulive: 'ViuLive',
  justNow: 'Just Now',
  or: 'Or',
  backToHome: 'Back to home',
  dropHere: 'Drop Here',
  handleRecaptcha: 'Handle reCAPTCHA',
  pleaseConfirmTheCaptcha: "I'm not a robot",
  liveWillStartIn: 'Live will start in',
  days: 'Days',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  clickTheBelowButtonToWatchTheEvent:
    'To access the online event, click the button below.',
  rotate: 'Rotate',
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  zooming: 'Zooming',
  rotation: 'Rotation',
  audio: 'Audio',
  startNewEvent: 'Create new Event',
  addNewStream: 'Add a new stream',
  copyToClipboard: 'Copy to clipboard',
  on: 'On',
  off: 'Off',
  wideScreen: 'Wide screen',
  mobileScreen: 'Mobile screen',
  mixer: 'Mixer',
  seperateWithComma: 'Separate tags with commas',
  category: 'Category',
  typeCameraPosition: 'Type a position',
  private: 'Private',
  public: 'Public',
  optional: 'Optional',
  selectCategoryToSeeVod: 'Select a category to see the VODs',
  inviteToBroadcast: 'Invite your friends to broadcast',
  payoutSettings: 'Payout options',
  darkTheme: 'Dark Theme',
  editProfile: 'Edit Profile',
  search: "Search",
  SignIn: "Sign in",
  SignUp: "Sign up",
  SignInTo: "Sign in to",
  noAccount: "Don't have an account?",
  emailAdress: "Email Address",
  fullName: "Full Name",
  confirm: "Confirm",
  multiPerformers: 'Multi Performers',
  series: 'Series',
  lastEventSeries: 'Last Event in the Series',
  addRemvoePerformer: 'Add/Remove Performer',
  createSeries: 'Create new series',
  save: 'Save',
  addPerformer: 'Add a Performer',
  searchByMail: 'Search By Email',
  add: 'Add',
  spotlight: 'Spotlight',
  remove:'Remove'
}
